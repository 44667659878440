import { buildMediaQueries } from '@rategravity/marketing-components';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  fonts,
  OwnUpHeader,
  OwnUpText
} from '@rategravity/own-up-component-library';
import styled, { css } from 'styled-components';
import { colors } from '../../modules/colors';

export const HeaderStyle = createOwnUpStyle({
  fontSize: '45px',
  lineHeight: '57px',
  ...fonts.TIEMPOS_HEADLINE_MEDIUM,
  fontWeight: 500,
  color: colors.PRIMARY,
  variants: {
    medium: {
      fontSize: '37px',
      lineHeight: '45px'
    },
    smallAndDown: {
      fontSize: '30px',
      lineHeight: '40px'
    }
  }
});

export const HeaderMixin = css`
  ${fonts.TIEMPOS_HEADLINE_MEDIUM};
  font-style: 'normal';
  margin: 0 auto 22px auto;
  font-size: 45px;
  line-height: 57px;
  font-weight: 500;
  color: ${colors.PRIMARY};
`;

export const Header = styled('h1')`
  ${HeaderMixin};
  ${buildMediaQueries('medium', {
    fontSize: '37px',
    lineHeight: '45px'
  })}
  ${buildMediaQueries('smallAndDown', {
    fontSize: '30px',
    lineHeight: '40px'
  })}
`;

export const QuoteStyle = createOwnUpStyle({
  fontSize: '24px',
  lineHeight: '27px',
  ...fonts.TIEMPOS_HEADLINE_REGULAR,
  fontWeight: 600,
  color: colors.PRIMARY
});

export const Quote = createOwnUpComponent(OwnUpText, QuoteStyle);

export const SubheadingStyle = createOwnUpStyle({
  fontSize: '22px',
  lineHeight: '27px',
  textAlign: 'center',
  ...fonts.GRAPHIK_MEDIUM,
  color: colors.PRIMARY
});

export const Subheading = createOwnUpComponent(OwnUpHeader, SubheadingStyle);

export const BoldTextStyle = createOwnUpStyle({
  fontSize: '14px',
  lineHeight: '10px',
  ...fonts.GRAPHIK_MEDIUM,
  color: colors.PRIMARY,
  variants: {
    cta: {
      fontSize: '16px'
    }
  }
});

export const BoldText = createOwnUpComponent(OwnUpText, BoldTextStyle);
