import loadable from '@loadable/component';
import {
  discoverConverter,
  Footer,
  Header,
  LandingFooter,
  myConverter,
  wwwConverter
} from '@rategravity/marketing-components';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox
} from '@rategravity/own-up-component-library';
import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import React, { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';
import { createGlobalStyle } from 'styled-components';
import { metadata } from '../../data/metadata/pages';
import { useMixpanel } from '../../hooks/use-mixpanel';
import { colors } from '../../modules/colors';
import { SEO } from '../seo';

const GreenWithHouse = loadable(() => import('../green-with-house'), {
  resolveComponent: (components) => components.GreenWithHouse
});

const AppWrapperStyle = createOwnUpStyle({
  margin: 'auto',
  maxWidth: '1280px',
  variants: {
    mediumAndDown: {
      paddingTop: '64px'
    }
  }
});

const AppWrapper = createOwnUpComponent(OwnUpBox, AppWrapperStyle);

const MainWrapper = createOwnUpComponent('main', {});

// Note: These styles may conflict with the global styles coming from the own up component library
// background-color, width: preserves styling when overflow is hidden
const GlobalStyle = createGlobalStyle`
body {
  background-color: ${colors.BACKGROUND};
  width: unset;
}
`;

interface PageNode {
  path: string;
}

interface Query {
  allPages: {
    nodes: PageNode[];
  };
}

// Extend the global window interface.
declare global {
  interface Window {
    dataLayer?: Record<string, unknown>[];
  }
}

// eslint-disable-next-line max-lines-per-function, abcsize/abcsize
export const Layout = ({ children }: PropsWithChildren<{}>) => {
  const { pathname, href } = useLocation();
  const meta = metadata[pathname];
  const mixpanel = useMixpanel();
  const discover = discoverConverter(process.env.GATSBY_DISCOVER);
  const my = myConverter(process.env.GATSBY_MY);
  const www = wwwConverter(process.env.GATSBY_WWW);

  const {
    allPages: { nodes: pages }
  }: Query = useStaticQuery(graphql`
    query {
      allPages: allSitePage {
        nodes {
          path
        }
      }
    }
  `);

  const conditionalRenderByPage = (pageList: string[]) => {
    const removeSlashes = (path: string) => path.replace(/\//g, '');
    return pages.some(({ path }) => {
      const p = removeSlashes(path);
      return p === removeSlashes(pathname) && !pageList.includes(p);
    });
  };
  // Pages on which we wont use the header
  const omitHeader = ['start-now'];
  const omitHeaderDirectoryCheck = conditionalRenderByPage(omitHeader);

  // Pages on which we will use the landing page footer and omit "GreenWithHouse" CTA.
  const landingPages = [
    'find-your-best-mortgage',
    'find-your-best-mortgage-mktg02',
    'get-started',
    'start-now'
  ];
  const landingPage = !conditionalRenderByPage(landingPages);

  return (
    <React.Fragment>
      <Helmet>
        <style>{`nav { background-color: ${colors.BACKGROUND} }`}</style>
      </Helmet>
      <GlobalStyle />
      {process.env.STORYBOOK_ENV === 'true' ? null : <SEO {...meta} />}
      <AppWrapper style={{ paddingTop: omitHeaderDirectoryCheck ? null : 0 }}>
        {omitHeaderDirectoryCheck ? (
          <Header
            {...{
              page: pathname,
              href,
              mainSite: true,
              discover,
              my,
              www,
              mixpanelHook: mixpanel
            }}
          />
        ) : null}
        <MainWrapper>{children}</MainWrapper>
      </AppWrapper>
      {landingPage ? null : <GreenWithHouse page={pathname} />}
      {landingPage ? (
        <LandingFooter {...{ mainSite: true, www }} />
      ) : (
        <Footer {...{ mainSite: true, www }} />
      )}
    </React.Fragment>
  );
};

export const FourOhFourLayout = ({ children }: PropsWithChildren<{}>) => {
  const { pathname, href } = useLocation();
  const meta = metadata[pathname];
  const mixpanel = useMixpanel();
  const discover = discoverConverter(process.env.GATSBY_DISCOVER);
  const my = myConverter(process.env.GATSBY_MY);
  const www = wwwConverter(process.env.GATSBY_WWW);

  return (
    <React.Fragment>
      <Helmet>
        <style>{`nav { background-color: ${colors.BACKGROUND} }`}</style>
      </Helmet>
      <GlobalStyle />
      {process.env.STORYBOOK_ENV === 'true' ? null : <SEO {...meta} />}
      <AppWrapper>
        <Header
          {...{
            page: pathname,
            href,
            mainSite: true,
            discover,
            my,
            www,
            mixpanelHook: mixpanel
          }}
        />
        <MainWrapper>{children}</MainWrapper>
      </AppWrapper>
      <Footer {...{ mainSite: true, www }} />
    </React.Fragment>
  );
};
