interface Metadata {
  title: string;
  description: string;
}

// Keys correspond to pathnames.
export const metadata: Record<string, Metadata> = {
  '/': {
    title: 'Find The Best Deal On Your Mortgage',
    description:
      "Own Up is a technology company that exists to make sure you get the best deal on your mortgage. Learn how we're bringing clarity to an outdated mortgage industry."
  },
  '/about/': {
    title: 'About',
    description:
      "Welcome to a new approach to the mortgage industry. We're here to help you find the best mortgage rate for you."
  },
  '/faq/': {
    title: 'FAQ',
    description:
      'Own Up is a technology company designed around people, not lenders. Learn how we can help you get your best mortgage rate today.'
  },
  '/find-your-best-mortgage/': {
    title: 'Find your best mortgage',
    description: 'Read more about how Own Up can save you time and money on your mortgage.'
  },
  '/find-your-best-mortgage-mktg02/': {
    title: 'Find your best mortgage',
    description: 'Read more about how Own Up can save you time and money on your mortgage.'
  },
  '/get-started/': {
    title: 'Get Started',
    description:
      'Rates in our marketplace beat 90% of offers out there. Complete our questionnaire and get started with your mortgage journey today!'
  },
  '/how-it-works/': {
    title: 'How It Works',
    description:
      'Learn how Own Up can provide you with unbiased expertise that leads to better financial outcomes.'
  },
  '/lender-partners/': {
    title: 'Own Up Lender Partners',
    description: "Learn more about Own Up's lender partners."
  },
  '/lender-network/': {
    title: 'Lender Network',
    description:
      "Learn more about Own Up's exclusive real-time customer acquisition platform for mortgage lenders."
  },
  '/licensing/': {
    title: 'Licensing',
    description: "Read more about Own Up's current licensure by state."
  },
  '/methodology/': {
    title: 'Methodology',
    description:
      'Read more about how customers who secure a mortgage from the Own Up marketplace save an average of $27,102 in interest over the life of their loan.'
  },
  '/onboarding/approved/': {
    title: 'Mortgage Approval Onboarding',
    description: 'Mortgage rates vary, so it pays to shop for financing. Build your profile now.'
  },
  '/onboarding/buying/': {
    title: 'Mortgage Purchase Onboarding',
    description:
      'Tell us where you are in the home purchasing process and start building your profile with us today!'
  },
  '/onboarding/get-started/': {
    title: 'Get Started',
    description: 'Get started by building your online profile with us today!'
  },
  '/onboarding/preapproved/': {
    title: 'Mortgage Pre-Approval Onboarding',
    description: 'Get pre-approved for a mortgage by building your profile with us today!'
  },
  '/privacy/': {
    title: 'Privacy',
    description:
      "Learn more about the Own Up privacy policy governing the use of Own Up's site and services."
  },
  '/real-estate/': {
    title: 'Real Estate',
    description:
      'We use technology and dedicated human advisors to simplify the lending process. Learn how we become a partner for buyers and their agents.'
  },
  '/reviews/': {
    title: 'Reviews',
    description:
      "We'll help you shop for the right loan. Read real reviews from our customers to see their experiences shopping for loans with Own Up."
  },
  '/start-now/': {
    title: 'Start Now',
    description:
      'Rates in our marketplace beat 90% of offers out there. Complete our questionnaire and get started with your mortgage journey today!'
  },
  '/terms-conditions/': {
    title: 'Terms & Conditions',
    description:
      "Learn more about the terms and conditions governing the use of Own Up's site and services."
  },
  '/3-reasons/': {
    title: '3 Reasons',
    description:
      'Learn more about how you might be overpaying for your mortgage. Own Up can help you get the best mortgage possible today.'
  },
  '/404/': {
    title: 'Page not found',
    description: 'Page not found'
  }
};
