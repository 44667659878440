import { buildMediaQueries } from '@rategravity/marketing-components';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  fonts,
  OwnUpHeader,
  OwnUpText
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../modules/colors';

export const PageTitle = styled(OwnUpHeader)`
  font-size: 54px;
  margin-bottom: 45px;
  ${buildMediaQueries(['medium', 'small'], {
    fontSize: '40px'
  })}
  ${buildMediaQueries('xsmall', {
    fontSize: '36px'
  })}
`;

export const SectionHeaderMixin = css`
  font-family: 'TiemposHeadline-Medium', 'Palatino', 'Times New Roman', 'serif';
  font-size: 52px;
  line-height: 1.275;
`;

export const SectionHeader = styled(OwnUpHeader)`
  ${SectionHeaderMixin}
  letter-spacing: 2px;
  ${buildMediaQueries(['medium', 'small'], {
    fontSize: '40px'
  })}
  ${buildMediaQueries('xsmall', {
    fontSize: '36px'
  })}
`;

const AltSectionHeaderStyle = createOwnUpStyle({
  fontFamily: "'TiemposHeadline-Medium', 'Palatino', 'Times New Roman', 'serif'",
  fontSize: '40px',
  letterSpacing: 2,
  lineHeight: 1.3,
  variants: {
    mediumAndDown: {
      fontSize: '26px'
    }
  }
});

export const AltSectionHeader = createOwnUpComponent(OwnUpHeader, AltSectionHeaderStyle);

export const ResponsiveSectionHeader = styled(OwnUpHeader)`
  ${SectionHeaderMixin}
  letter-spacing: 2px;
  ${buildMediaQueries(['medium', 'small'], {
    fontSize: '40px',
    textAlign: 'center'
  })}
  ${buildMediaQueries('xsmall', {
    fontSize: '32px',
    textAlign: 'center'
  })}
`;

export const PrimaryTextStyle = createOwnUpStyle({
  ...fonts.GRAPHIK_REGULAR,
  display: 'block',
  fontFamily: "'Graphik-Regular', 'Arial', 'sans-serif'",
  fontSize: '19px',
  fontWeight: 100,
  lineHeight: 1.6
});

export const PrimaryTextMixin = css`
  ${fonts.GRAPHIK_REGULAR}
  color: ${colors.PRIMARY};
  display: block;
  font-family: 'Graphik-Regular', 'Arial', 'sans-serif';
  font-size: 19px;
  font-weight: 100;
  line-height: 1.6;
`;

export const PrimaryText = styled((props) => <OwnUpText {...props} />)`
  ${PrimaryTextMixin}
`;

export const BoldPrimaryText = styled(OwnUpText)`
  ${PrimaryTextMixin}
  ${fonts.GRAPHIK_SEMIBOLD}
`;

export const ResponsivePrimaryTextMixin = css`
  ${PrimaryTextMixin}
  ${buildMediaQueries('mediumAndDown', {
    textAlign: 'center'
  })}
`;

export const ResponsivePrimaryText = styled(OwnUpText)`
  ${ResponsivePrimaryTextMixin}
`;

const CatchphraseStyle = createOwnUpStyle({
  ...fonts.TIEMPOS_HEADLINE_MEDIUM,
  color: colors.PRIMARY,
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '21px',
  lineHeight: 1.3,
  textAlign: 'center',
  variants: {
    mediumAndDown: {
      fontSize: '26px'
    }
  }
});

export const Catchphrase = createOwnUpComponent(OwnUpText, CatchphraseStyle);

const ResponsiveCatchphraseStyle = createOwnUpStyle({
  ...CatchphraseStyle,
  variants: {
    mediumAndDown: {
      display: 'block'
    }
  }
});

export const ResponsiveCatchphrase = createOwnUpComponent(OwnUpText, ResponsiveCatchphraseStyle);

const HighlightStyle = createOwnUpStyle({
  color: 'unset', // Enable inheritance from parent.
  backgroundColor: colors.MINT
});

export const Highlight = createOwnUpComponent('mark', HighlightStyle);

const HighlightAltStyle = createOwnUpStyle({
  color: 'unset', // Enable inheritance from parent.
  backgroundColor: colors.LIGHT_MINT
});

export const HighlightAlt = createOwnUpComponent('mark', HighlightAltStyle);

const ErrorTextStyle = createOwnUpStyle({
  color: 'red',
  padding: '0 10px',
  display: 'block',
  variants: {
    smallAndDown: {
      paddingBottom: '20px'
    }
  }
});

export const ErrorText = createOwnUpComponent(OwnUpText, ErrorTextStyle);

const LightHeaderStyle = createOwnUpStyle({
  ...fonts.GRAPHIK_MEDIUM,
  fontFamily: "'Graphik-Medium', 'Arial', 'sans-serif'",
  color: colors.GREY_TEXT,
  fontSize: '16px'
});

export const LightHeader = createOwnUpComponent(OwnUpHeader, LightHeaderStyle);
